import AdminLayout from '@admin/layouts/AdminLayout'

export default {
  path: '/labo',
  component: AdminLayout,

  children: [
    {
      path: 'pdf_export',
      name: 'PdfExportPoc',
      component: () => import('@admin/pages/labo/PdfExportPoc')
    },
    {
      path: 'database_poc',
      name: 'DatabasePoc',
      component: () => import('@admin/pages/labo/DatabasePoc')
    },
    {
      path: 'test',
      name: 'Test',
      component: () => import('@admin/pages/labo/Test')
    },
    {
      path: 'net_impact_score_summary',
      name: 'NetImpactScoreSummaryPoc',
      component: () => import('@admin/pages/labo/NetImpactScoreSummaryPoc')
    },
    {
      path: 'notion',
      name: 'Notion',
      component: () => import('@admin/pages/labo/Notion')
    },
    {
      path: 'shortenr',
      name: 'Shortener',
      component: () => import('@admin/pages/labo/Shortener')
    },
    {
      path: 'hostfile',
      name: 'HostFile',
      component: () => import('@admin/pages/labo/HostFile')
    },
    {
      path: 'proxy',
      name: 'Proxy',
      component: () => import('@admin/pages/labo/Proxy')
    }
  ]
}
