<template>
  <v-navigation-drawer
    v-if="!$inIframe"
    v-model="drawer"
    color="white"
    permanent
    clipped
    dense
    flat
    app
  >
    <v-list>
      <v-list-item>
        <v-list-item-icon>
          <v-icon>mdi-account</v-icon>
        </v-list-item-icon>

        <v-list-item-title>Admin</v-list-item-title>
      </v-list-item>

      <v-divider class="mt-1" />

      <template v-for="menuItem in menuItems">
        <template v-if="!menuItem.childs">
          <v-list-item class="main-nav-tabs"
            :key="menuItem.title"
            :to="menuItem.to"
            :ripple="false"
          >
            <v-list-item-icon>
              <v-icon>{{ menuItem.icon }}</v-icon>
            </v-list-item-icon>

            <v-list-item-title>
              {{ menuItem.title }}
            </v-list-item-title>
          </v-list-item>
        </template>

        <template v-else>
          <v-list-group
            :key="menuItem.title"
            :prepend-icon="menuItem.icon"
            :value="menuItem.opened === false ? false : true"
          >
            <template v-slot:activator>
              <v-list-item-title>{{ menuItem.title }}</v-list-item-title>
            </template>

            <v-list-item
              v-for="menuItemChild in menuItem.childs"
              :key="menuItemChild.title"
              :to="menuItemChild.to"
              :ripple="false"
            >
              <v-list-item-icon>
                <v-icon>{{ menuItemChild.icon }}</v-icon>
              </v-list-item-icon>

              <v-list-item-title>
                {{ menuItemChild.title }}
              </v-list-item-title>
            </v-list-item>
          </v-list-group>
        </template>
      </template>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
  export default {
    name: 'SettingsdSideBar',
    data() {
      return {
        drawer: true,
        menuItems: [
          {
            title: "Export Brands and Campaigns",
            icon: "mdi-store", //todo : a changer
            to: { name: "BrandsExport" }
          },
          {
            title: "Monitoring",
            icon: "mdi-home-analytics",
            opened: false,
            childs: [
              {
                title: "Rapports Local Insight",
                to: { name: "MonitoringLocalInsightReporting" },
                icon: "mdi-star-check-outline"
              }
            ]
          },
          {
            title: "Local Monitor",
            icon: "mdi-map-marker",
            opened: false,
            childs: [
              {
                title: "Graphique",
                to: { name: "LmGraph" },
                icon: "mdi-chart-timeline-variant"
              },
              {
                title: "Tableau des inscrits",
                to: { name: "LmTable" },
                icon: "mdi-table"
              }
            ]
          },
          {
            title: "Local Insight",
            icon: "mdi-map-marker-radius",
            opened: !!window.location.href.match("/local_insights/"),
            childs: [
              {
                title: "Enseignes nationales",
                to: { name: "LmBrands" },
                icon: "mdi-store"
              },
              {
                title: "Enseignes localisées",
                to: { name: "LmBrandFactories" },
                icon: "mdi-store"
              },
              {
                title: "Recherche par activité",
                to: { name: "GooglePlaces" },
                icon: "mdi-shopping-search"
              },
              {
                title: "Verticales",
                to: { name: "Verticals" },
                icon: "mdi-shopping-search"
              },
            ]
          },
          {
            title: "Verbatim",
            icon: "mdi-card-text-outline",
            opened: !!window.location.href.match("/verbatim/"),
            childs: [
              {
                title: "Moteur de recherche",
                to: { name: "VerbatimSearchEngine" },
                icon: "mdi-text-search"
              },
            ]
          },
          {
            title: "Facturation",
            icon: "mdi-cash-multiple",
            to: { name: "Billing" }
          },
          {
            title: "Net Impact Score",
            icon: "mdi-chart-line",
            to: { name: "NetImpactScore" }
          },
          {
            title: "Theme",
            icon: "mdi-pencil-ruler",
            opened: !!window.location.href.match("/theme/"),
            childs: [
              {
                title: "Général",
                icon: "mdi-text",
                to: { name: "General" }
              },
              {
                title: "Icônes",
                icon: "mdi-emoticon-excited-outline",
                to: { name: "Icons" }
              },
              {
                title: "Couleurs",
                icon: "mdi-palette",
                to: { name: "Colors" }
              },
              {
                title: "Alertes",
                icon: "mdi-alert",
                to: { name: "Snackbar" }
              },
              {
                title: "Charts",
                icon: "mdi-chart-line",
                to: { name: "Charts" }
              },
              {
                title: "Repartition",
                icon: "mdi-chart-bar",
                to: { name: "Repartition" }
              },
              {
                title: "Friezes",
                icon: "mdi-chart-bar",
                to: { name: "Frieze" }
              },
              {
                title: "Dates",
                icon: "mdi-calendar-range",
                to: { name: "Dates" }
              },
              {
                title: "Widgets",
                icon: "mdi-widgets",
                to: { name: "Widgets" }
              },
              {
                title: "Infinite Scroll",
                icon: "mdi-infinity",
                to: { name: "InfiniteScroll" }
              },
              {
                title: "Rate Bar",
                icon: "mdi-rectangle-outline",
                to: { name: "RateBar" }
              },
              {
                title: "WAutocomplete",
                icon: "mdi-auto-fix",
                to: { name: "WAutocomplete" }
              },
              {
                title: "WSearchInput",
                icon: "mdi-magnify",
                to: { name: "WSearchInput" }
              },
              {
                title: "WDataTableComponent",
                icon: "mdi-magnify",
                to: { name: "WDataTableComponent" }
              },
              {
                title: "WTreeSelect",
                icon: "mdi-tree",
                to: { name: "WTreeSelect" }
              },
              {
                title: "WFunnelChart",
                icon: "mdi-filter-multiple-outline",
                to: { name: "WFunnelChart" }
              }
            ]
          },
          {
            title: "Labo",
            icon: "mdi-test-tube",
            opened: !!window.location.href.match("/labo/"),
            childs: [
              {
                title: "Test",
                to: { name: "Test" }
              },
              {
                title: "Pdf Export",
                to: { name: "PdfExportPoc" }
              },
              {
                title: "Database Voronoi",
                to: { name: "DatabasePoc" }
              },
              {
                title: "NetImpactScoreSummary",
                to: { name: "NetImpactScoreSummaryPoc" }
              },
              {
                title: "Notion",
                to: { name: "Notion" }
              },
              {
                title: "Shortener",
                to: { name: "Shortener" }
              },
              {
                title: "Hoster un fichier",
                to: { name: "HostFile" }
              },
              {
                title: "Proxy",
                to: { name: "Proxy" }
              }
            ]
          },
          {
            title: "OpenAi",
            icon: "mdi-brain",
            opened: !!window.location.href.match("/openai/"),
            childs: [
              {
                title: "Taxonomy",
                to: { name: "Taxonomy" }
              }
            ]
          },
          {
            title: "Constants",
            icon: "mdi-wrench-outline",
            to: { name: "AdminConstants" }
          },
          {
            title: "Tracking Events",
            icon: "mdi-track-light",
            to: { name: "TrackingEvents" }
          },
          {
            title: "PubSubMessages",
            icon: "mdi-bell-circle-outline",
            to: { name: "PubSubMessages" }
          },
          {
            title: "Legacy",
            icon: "mdi-account-tie",
            to: { name: 'AdminLegacy' }
          },
        ]


      }
    }
  }
</script>

<style lang="stylus" scoped></style>
