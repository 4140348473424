import AdminLayout from '@admin/layouts/AdminLayout'

export default {
  path: '/theme',
  component: AdminLayout,
  redirect: { name: "LmTable" },

  children: [
    {
      path: 'icons',
      name: 'Icons',
      component: () => import('@admin/pages/theme/Icons')
    },
    {
      path: 'colors',
      name: 'Colors',
      component: () => import('@admin/pages/theme/Colors')
    },
    {
      path: 'general',
      name: 'General',
      component: () => import('@admin/pages/theme/General')
    },
    {
      path: 'snackbar',
      name: 'Snackbar',
      component: () => import('@admin/pages/theme/Snackbar')
    }
    ,
    {
      path: 'charts',
      name: 'Charts',
      component: () => import('@admin/pages/theme/Charts')
    },
    {
      path: 'repartitions',
      name: 'Repartition',
      component: () => import('@admin/pages/theme/Repartition')
    },
    {
      path: 'friezes',
      name: 'Frieze',
      component: () => import('@admin/pages/theme/Frieze')
    },
    {
      path: 'dates',
      name: 'Dates',
      component: () => import('@admin/pages/theme/Dates')
    },
    {
      path: 'widgets',
      name: 'Widgets',
      component: () => import('@admin/pages/theme/Widgets')
    },
    {
      path: 'infinite_scroll',
      name: 'InfiniteScroll',
      component: () => import('@admin/pages/theme/InfiniteScroll')
    },
    {
      path: 'rate_bar',
      name: 'RateBar',
      component: () => import('@admin/pages/theme/RateBar')
    },
    {
      path: 'w_autocomplete',
      name: 'WAutocomplete',
      component: () => import('@admin/pages/theme/WAutocompleteComponent')
    },
    {
      path: 'w_search_input',
      name: 'WSearchInput',
      component: () => import('@admin/pages/theme/WSearchInput')
    },
    {
      path: 'w_group_table_component',
      name: 'WDataTableComponent',
      component: () => import('@admin/pages/theme/WDataTableComponent')
    },
    {
      path: 'w_tree_select',
      name: 'WTreeSelect',
      component: () => import('@admin/pages/theme/WTreeSelect')
    },
    {
      path: 'w_funnel_chart',
      name: 'WFunnelChart',
      component: () => import('@admin/pages/theme/WFunnelChart')
    },
  ]
}
