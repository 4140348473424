<template>
  <div class="admin-layout">
    <AppBar />
    <AdminSideBar />
    <v-main>
      <v-container fluid>
        <router-view />
      </v-container>
    </v-main>
  </div>
</template>

<script>
  import AppBar from "./navigation/AppBar"
  import AdminSideBar from "./navigation/AdminSideBar"

  export default {
    name: 'AdminLayout',
    components: {
      AdminSideBar,
      AppBar
    }
  }
</script>

<style lang="stylus" scoped>
</style>
