import AdminLayout from '@admin/layouts/AdminLayout'

export default {
  path: '/openai',
  component: AdminLayout,

  children: [
    {
      name: 'Taxonomy',
      path: 'scenarios/:campaignId?',
      props: (route) => ({
        campaignId: route.params.campaignId
      }),
      component: () => import('@admin/pages/openai/Taxonomy')
    }
  ]
}
