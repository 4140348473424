import HomeLayout from '@admin/layouts/HomeLayout'

export default {
  path: '/',
  component: HomeLayout,
  redirect: { name: 'Login' },
  children: [{
      path: '/login',
      name: 'Login',
      meta: { public: true, authRedirected: "Welcome" },
      component: () => import('@admin/pages/home/LoginPage')
    },
    {
      path: 'logout',
      name: 'Logout',
      meta: { public: true },
      async beforeEnter(to, from, next) {
        localStorage.removeItem('wizvilleSessionToken')
        window.location.href = "/login"
      },
      component: () => import('@admin/pages/home/LoginPage')
    }
  ]
}
