import App from '@admin/app.vue'
import { router } from '@admin/router'
import Vue from 'vue'
import { i18n, setLanguageAsync } from '@i18n/setup'
import wizville from '@vue/plugins/wizville'
import vuetify from '@vue/plugins/vuetify'
import store from '@admin/store'
import { initSession } from '@shared/router/sessionGuards'
import { LoaderPlugin } from 'vue-google-login';
import { googleClientId } from "@shared/constants"

Vue.use(LoaderPlugin, {
  client_id: googleClientId
});

if (!localStorage.wizvilleSessionToken) {
  Vue.GoogleAuth.then(auth2 => {
    auth2.signOut()
  }).catch(e => {
    store.dispatch('googleSignIn/setEnabled', false)
  })
}

window.$store = store
document.addEventListener('DOMContentLoaded', () => {
  setLanguageAsync('default').then(() => {
    const el = document.body.appendChild(document.createElement('admin'))

    initSession()
    window.app = new Vue({
      el,
      vuetify,
      wizville,
      store,
      i18n,
      router,
      render: h => h(App)
    })
  })
})
