import Vue from 'vue'
import Vuex from 'vuex'
import snackbar from '@store/snackbar.js'
import googleSignIn from '@store/googleSignIn.js'
import adminContext from '@store/contexts/adminContext.js'

Vue.use(Vuex)

const store = new Vuex.Store({
  strict: process.env.NODE_ENV !== 'production',
  plugins: [],

  modules: {
    snackbar,
    adminContext,
    googleSignIn
  }
})

export default store
