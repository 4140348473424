import AdminLayout from '@admin/layouts/AdminLayout'

export default {
  path: '/verbatim',
  component: AdminLayout,

  children: [
    {
      path: 'search_engine',
      name: 'VerbatimSearchEngine',
      component: () => import('@admin/pages/verbatim/SearchEngine')
    },
  ]
}
