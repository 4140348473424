const googleSignIn = {
  namespaced: true,

  state: () => ({
    enabled: true
  }),
  getters: {
    isEnabled(state) { return state.enabled },
  },
  mutations: {
    SET_ENABLED(state, isEnabled) {
      state.enabled = isEnabled
    },
  },
  actions: {
    setEnabled(context, isEnabled) {
      context.commit('SET_ENABLED', isEnabled)
    }
  }
}

export default googleSignIn

