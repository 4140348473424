<template>
  <v-app id="admin">
    <WProfiler v-if="showBasedPanel" scope="admin" />

    <WIconsBase />
    <WSnackBar />
    <router-view />
  </v-app>
</template>

<script>
  export default {
    data() {
      return {
        basedPanel: (localStorage.basedPanel === undefined ? `${this.$local}` : `${localStorage.basedPanel === 'true'}`)
      }
    },
    mounted() {
      this._keyListener = function(e) {
        if (e.key === "j" && (e.ctrlKey || e.metaKey)) {
          this.basedPanel = localStorage.basedPanel = (this.basedPanel === 'true' ? 'false' : 'true')
        }
      }

      document.addEventListener('keydown', this._keyListener.bind(this))
    },
     computed: {
      showBasedPanel() {
        return this.basedPanel === 'true' && this.$route.name !== 'Login'
      },
    }
  }
</script>
