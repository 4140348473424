<template>
  <v-app-bar
    v-if="!$inIframe"
    color="primary"
    clipped-left
    dense
    dark
    flat
    app
  >
    <a class="logo-wizville" :class="'logo-' + $env" href="/">
      <WIcon icon="wizville" :class="'logo-' + $env" :icon-style="{fontSize: '30px', color: 'fff'}"/>
    </a>

    <v-toolbar-title class="pl-3 title">
      <router-link to="Welcome">Admin</router-link>
    </v-toolbar-title>

    <v-spacer></v-spacer>

    <div style="width: 350px">
      <Autologin />
    </div>

    <v-menu
      bottom
      left
      offset-y
    >
      <template v-slot:activator="{ on }">
        <v-btn
          text
          tile
          v-on="on"
          :ripple="false"
          style="height: 100%"
        >
          {{ currentAdmin.email }}
        </v-btn>
      </template>

      <v-list>
        <v-list-item key="logout" :ripple="false" :to="{ name: 'Logout' }">
          <v-list-item-icon><v-icon>mdi-logout</v-icon></v-list-item-icon>
          <v-list-item-title>Se déconnecter</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </v-app-bar>
</template>

<script>
  import Autologin from './Autologin'
  import { mapGetters } from 'vuex'

  export default {
    name: 'AppBar',
    components: {
      Autologin,
    },
    data() {
      return {
        drawer: true
      }
    },
    computed: {
      ...mapGetters([
        "currentAdmin"
      ])
    }
  }
</script>
<style lang="stylus" scoped>

.logo-wizville
  color: #fff
  height: 100%
  width: 46px
  background-color: #65c095
  text-align: center
  line-height: 46px
  display: inline-block
.logo-dev
  background-color: red
.logo-production
  background-color: #65c095
.logo-preprod
  background-color: orange
.brand-logo
  float: left
  width: 46px
  display: inline-block


  .title
    a
      color: #fff

</style>
