import Vue from 'vue'
import Router from 'vue-router'
import homeRouter from './home'
import adminRouter from './admin'
import themeRouter from './theme'
import laboRouter from './labo'
import verbatimRouter from './verbatim'
import openAiRouter from './openai'
import store from "@admin/store"

import { initSessionGuards } from '@shared/router/sessionGuards'

Vue.use(Router)

const router = new Router({
  // This removes # from the route
  mode: 'history',
  routes: [
    homeRouter,
    adminRouter,
    themeRouter,
    laboRouter,
    verbatimRouter,
    openAiRouter,
  ]
})

initSessionGuards(router, store, 'adminContext')

window.vueRouter = router

export { router }
