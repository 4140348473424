<template>
  <v-main class="home-layout">
    <router-view />
  </v-main>
</template>

<script>
  export default {
    name: 'HomeLayout'
  }
</script>

<style lang="stylus" scoped>
  .home-layout
    background-color: #323232
</style>
